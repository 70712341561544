export default [
  {
    path: "/",
    component: () => import("~v/LayoutView"),
    children: [
      {
        path: "/",
        meta: {
          title: "数据统计",
          icon: "home",
          hide: false,
          type: "home",
        },
        component: () => import("~v/HomeView"),
      },
      {
        path: "/user",
        meta: {
          title: "用户管理",
          icon: "team",
          hide: false,
          type: "user",
        },
        component: () => import("~v/UserList"),
      },
      {
        path: "/role",
        meta: {
          title: "角色管理",
          icon: "solution",
          hide: false,
          type: "user",
        },
        component: () => import("~v/RoleList"),
      },
      {
        path: "/params",
        meta: {
          title: "参数管理",
          icon: "setting",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Params"),
      },
      {
        path: "/params/add/:id",
        props:true,
        meta: {
          title: "添加参数",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Params/AddOrUpdate"),
      },
      {
        path: "/banner",
        meta: {
          title: "轮播图管理",
          icon: "edit",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Banner"),
      },
      {
        path: "/banner/add/:id",
        //对应页面组件可以使用props来接受参数
        props:true,
        meta: {
          title: "添加轮播图",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Banner/AddOrUpdate"),
      },
      {
        path: "/author",
        meta: {
          title: "作者管理",
          icon: "user",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Author"),
      },
      {
        path: "/author/add/:id",
        //对应页面组件可以使用props来接受参数
        props:true,
        meta: {
          title: "添加作者",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Author/AddOrUpdate"),
      },
      {
        path: "/article",
        meta: {
          title: "文章管理",
          icon: "book",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Article"),
      },
      {
        path: "/article/add/:id",
        //对应页面组件可以使用props来接受参数
        props:true,
        meta: {
          title: "添加文章",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Article/AddOrUpdate"),
      },
      {
        path: "/type",
        meta: {
          title: "分类管理",
          icon: "menu",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Type"),
      },
      {
        path: "/type/add/:id",
        props:true,
        meta: {
          title: "添加分类",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Type/AddOrUpdate"),
      },
      {
        path: "/video",
        meta: {
          title: "视频管理",
          icon: "book",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Video"),
      },
      {
        path: "/video/add/:id",
        props:true,
        meta: {
          title: "添加视频",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Video/AddOrUpdate"),
      },
      {
        path: "/vip",
        meta: {
          title: "会员管理",
          icon: "user",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Vip"),
      },
      {
        path: "/vip/add/:id",
        props:true,
        meta: {
          title: "添加价格",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/Vip/AddOrUpdate"),
      },
      {
        path: "/comment",
        meta: {
          title: "评论管理",
          icon: "edit",
          hide: false,
          type: "home",
        },
        component: () => import("~v/Comment"),
      },
      {
        path: "/match_comment",
        meta: {
          title: "比赛评论管理",
          icon: "edit",
          hide: false,
          type: "home",
        },
        component: () => import("~v/MatchComment"),
      },
      {
        path: "/match_video",
        meta: {
          title: "比赛视频管理",
          icon: "edit",
          hide: false,
          type: "home",
        },
        component: () => import("~v/MatchVideo"),
      },
      {
        path: "/match_video/add/:id",
        props:true,
        meta: {
          title: "添加视频",
          icon: "plus",
          hide: true,
          type: "home",
        },
        component: () => import("~v/MatchVideo/AddOrUpdate"),
      },
    ],
  },
  {
    path: "/login",
    meta: {
      title: "登录页面",
      icon: "logout",
      hide: false,
      type: "logout",
    },
    component: () => import("~v/LoginView"),
    children: [
      {
        path: "/",
        meta: {
          hide: false,
          type: "logout",
        },
        component: () => import("~v/User"),
      },
    ]
  },
];
